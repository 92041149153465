import React from "react"
import { Link, graphql } from "gatsby"

import { Container, Row, Col } from 'react-bootstrap';

import Image from '../components/image';
import Layout from "../components/layout"
import SEO from "../components/seo"

export default (props) => {

    const { currentPage, numPages } = props.pageContext

    return (
      <Layout>
        <SEO title="News + Events" />
            <style>{`
            .blog-image-wrapper {
                overflow: hidden;
                max-height: 210px;
            }
            .blog-image {
                transition: transform .2s;
            }
            .blog-image:hover {
                -ms-transform: scale(1.1); /* IE 9 */
                -webkit-transform: scale(1.1); /* Safari 3-8 */
                transform: scale(1.1);
            }
            .blog-title {
                letter-spacing: 4px;
                font-weight: 400;
            }
            .blog-link:hover {
                text-decoration: none;
            }
            `}</style>
        <div className="page-container page-container-mt">
            <Container>
                <Row>
                    <div className="col">
                        <h1 className="mb-5">NEWS + EVENTS</h1>
                    </div>
                </Row>
                <Row>

                    {props.data.allMarkdownRemark.edges.map(({ node }, index) => {
                        return (
                            <Col md={4} key={index}>
                                <Link to={node.fields.slug} className="blog-link">
                                    <div className="blog-image-wrapper mb-4">
                                        <Image className="blog-image" src={`events/${node.frontmatter.image}`} alt={node.frontmatter.title} />
                                    </div>
                                    <h3 className="blog-title">{node.frontmatter.title}</h3>
                                </Link>
                            </Col>
                        )
                    })}
                </Row>
                {numPages > 1 ? (
                    <Row>
                        <Col>
                            <nav aria-label="News navigation">
                            <ul className="pagination justify-content-center">
                                {Array.from({ length: numPages }, (_, i) => (
                                    <li key={i} className={`page-item ${i+1 === currentPage ? 'active' : ''}`}>
                                        <Link className="page-link" to={`/news/${i === 0 ? "" : i + 1}`}>
                                            {i + 1}
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                            </nav>
                        </Col>
                    </Row>
                ): null}
            </Container>
        </div>
      </Layout>
    )
}

export const query = graphql`
query eventQuery($skip: Int, $limit: Int) {
  allMarkdownRemark(sort: {fields: [frontmatter___date], order: DESC}, limit: $limit, skip: $skip) {
    edges {
      node {
        id
        frontmatter {
          title
          image
        }
        fields {
          slug
        }
      }
    }
  }
}

`